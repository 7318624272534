import { Suspense } from 'react';

import { Layout } from 'components';
import LandingPage from 'pages/Landing';
import { createBrowserRouter } from 'react-router-dom';

import { routes } from './routesConst';

export const router = createBrowserRouter([
  {
    path: routes.base,
    element: (
      <Suspense fallback={<p>Loading book details...</p>}>
        <Layout>
          <LandingPage />
        </Layout>
      </Suspense>
    ),
  },
  // {
  //   element: <PrivateLayout />,
  //   children: [
  //     {
  //       path: `/${routes.collections}`,
  //       element: <CollectionsPage />,
  //     },
  //     {
  //       path: combineRoutes.createCollection,
  //       element: <CollectionFormPage />,
  //     },
  //     {
  //       path: combineRoutes.collectionDetail,
  //       element: <CollectionPage />,
  //     },
  //     {
  //       path: combineRoutes.createNFT,
  //       element: <CreateNFTPage />,
  //     },
  //     { path: `/${routes.bridge}`, element: <BridgePage /> },
  //     { path: combineRoutes.createBridge, element: <CreateBridgeNFTPage /> },
  //     {
  //       path: `/${routes.fractionalize}`,
  //       element: <>Fractionalize</>,
  //     },
  //     {
  //       path: '*',
  //       element: <PageNotFound />, // Catch-all route for 404
  //     },
  //   ],
  // },
]);
