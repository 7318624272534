export const Chip = ({ text }: { text: string }) => {
  return (
    <div className="flex justify-center items-center gap-2 w-fit px-4 md:px-9 py-2 md:py-[23px] bg-grayBg hover:bg-blackBg cursor-pointer backdrop-blur-[10px] bg-opacity-50 rounded-full font-Jakarta text-white text-[13px] md:text-2xl leading-9 font-semibold group h-[80px]">
      <img
        src="/icons/hand.png"
        className="w-0 group-hover:w-[42px] opacity-0 group-hover:opacity-100 transition-opacity"
      />
      {text}
    </div>
  );
};

export default Chip;
