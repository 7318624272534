import { Landing } from 'module/Landing';

import { Suspense } from 'react';

const LandingPage = () => {
  return (
    <Suspense fallback={<h1>Loading</h1>}>
      <Landing />
    </Suspense>
  );
};

export default LandingPage;
