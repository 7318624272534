import { ToastErrorIcon, ToastSuccessIcon } from 'components/Icons';
import { Toaster } from 'react-hot-toast';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes/routes';

function App() {
  return (
    <div className="max-h-screen App">
      <RouterProvider router={router} />
      <Toaster
        position="bottom-right"
        toastOptions={{
          className: '!text-white !bg-[#171717] font-["Aeonik"] !rounded-full text-xs !p-3',
          duration: 2000,
          error: { icon: <ToastErrorIcon fill="red" /> },
          success: { icon: <ToastSuccessIcon /> },
        }}
      />
    </div>
  );
}

export default App;
