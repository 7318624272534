import { GetStartedButton } from 'module/Landing/GetStartedButton';

import { useEffect, useState } from 'react';

import { Hamburger } from 'components/Icons';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { NavbarOptions, classes } from 'utils';

import { RightArrowIcon } from '../Icons/svg';
import { TypeFormButton } from '../TypeFormButton/TypeFormButton';

export function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const app = document.querySelector('.App')?.classList;

    if (isMenuOpen) {
      app?.add('stop-scrolling');
      app?.add('bg-white');
    } else {
      app?.remove('stop-scrolling');
      app?.remove('bg-white');
    }
  }, [isMenuOpen]);

  const clickHandler = (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
      | React.KeyboardEvent<HTMLSpanElement>,
    id: string
  ): void => {
    event.preventDefault();
    const element = document.getElementById(id);

    if (element) {
      setIsMenuOpen(false);
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  };

  const PixelPortLogo = ({ className }: { className?: string }) => (
    <Link
      to={routes.base}
      className={classes(
        "leading-[22px] uppercase z-[2] text-[32px] lg:text-[44px] font-['Arcade'] ",
        className
      )}
    >
      Pixelport
    </Link>
  );

  const CloseButton = () => {
    return (
      <button
        className="relative z-[2] -top-[2px] block h-6 w-6 cursor-pointer md:hidden"
        onClick={() => setIsMenuOpen((prev) => !prev)}
      >
        <div
          className={classes(
            "transition-menu-button before:transition-menu-button after:transition-menu-button absolute h-[2px] w-5 bg-black duration-300 ease-in-out before:absolute before:-top-2 before:block before:h-[2px] before:w-5 before:bg-black before:duration-300 before:ease-in-out before:content-[''] after:absolute after:top-2 after:block after:h-[2px] after:w-5 after:bg-black after:duration-300 after:ease-in-out after:content-['']",
            isMenuOpen &&
              'bg-transparent before:top-0 before:rotate-45 after:top-0 after:-rotate-45'
          )}
        />
      </button>
    );
  };

  const MenuItem = () => (
    <>
      {NavbarOptions.map(({ name, href }) => (
        <button
          key={name}
          className={classes(
            'z-[2] relative font-Jakarta font-medium uppercase text-base',
            !isMenuOpen
              ? 'cursor-pointer'
              : 'border-y border-[#E3E3E366] pt-[15px] pb-3 mx-3 border-t-0 first:border-t'
          )}
          onClick={(event) => clickHandler(event, href.substring(1))}
        >
          <span
            role="button"
            tabIndex={0}
            className={classes('flex justify-between w-full', isMenuOpen && 'items-center')}
            onClick={(event) => event.preventDefault()}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                clickHandler(event, href.substring(1));
              }
            }}
          >
            {name}
            {isMenuOpen && <RightArrowIcon />}
          </span>
        </button>
      ))}
    </>
  );

  return (
    <div className="absolute top-7 w-full z-20">
      <div className="md:flex hidden items-center justify-center backdrop-blur-[5px] w-fit mx-auto rounded-full px-2.5 2xl:p-2.5 shadow-navbar-shadow border border-[#83838333] gap-[35px]">
        <PixelPortLogo className={'mt-[14px] ml-10'} />
        <MenuItem />
        <div className="ml-6">
          <TypeFormButton formId="uvT7QzSD" as="div">
            <GetStartedButton
              title="get early access"
              arrowStroke="black"
              classNames={{
                base: '!bg-black !text-white',
                container:
                  'bg-white lg:group-hover:translate-x-[200px] sm:group-hover:translate-x-[165px]',
              }}
            />
          </TypeFormButton>
        </div>
        <CloseButton />
      </div>
      <div className="flex md:hidden items-center justify-between backdrop-blur-[5px] mx-4 sm:mx-auto rounded-full shadow-navbar-shadow border border-[#83838333] gap-[35px] px-4">
        <PixelPortLogo className={'mt-3 ml-2 leading-0'} />
        <button onClick={() => setIsMenuOpen(true)}>
          <Hamburger />
        </button>
      </div>
      {isMenuOpen && (
        <div className="fixed top-0 left-0 w-full h-full z-20 md:hidden ">
          <div className="bg-white w-full h-full p-4 py-6">
            <div className="w-full h-full rounded-[10px]">
              <div className="flex justify-between items-baseline p-3">
                <PixelPortLogo />
                <CloseButton />
              </div>
              <div className="flex flex-col h-full">
                <MenuItem />
                <div className="w-full">
                  <TypeFormButton
                    formId="uvT7QzSD"
                    as="div"
                    className="absolute right-3 bottom-6 top-auto left-[48%] transform -translate-x-1/2 z-10"
                  >
                    <GetStartedButton
                      title="get early access"
                      arrowStroke="black"
                      classNames={{
                        base: '!bg-black !text-white',
                        container:
                          'bg-white lg:group-hover:translate-x-[200px] sm:group-hover:translate-x-[165px]',
                      }}
                    />
                  </TypeFormButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
