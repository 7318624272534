import { ClassNames, HTMLAttributes } from 'types';
import { classes } from 'utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  tag?: string | React.ComponentType<HTMLAttributes<unknown>>;
  classNames?: ClassNames<'header' | 'accessory'>;
  accessory?: React.ReactNode;
}

export function Header({ children, className, classNames, tag: Tag = 'h3', accessory }: Props) {
  return (
    <div className={classes('text-light-black text-2xl', className, classNames?.base)}>
      {!!children && (
        <Tag className={classes('uppercase max-w-full', classNames?.header)}>{children}</Tag>
      )}
      {accessory && (
        <div
          className={classes(
            'flex mb-4 space-x-3 [&>*]:h-full w-full sm:w-auto [&>*]:flex-1',
            classNames?.accessory
          )}
        >
          {accessory}
        </div>
      )}
    </div>
  );
}

export default Header;
