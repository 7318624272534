import { Props } from './types';

export function ContactsIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2772_1340)">
        <g clipPath="url(#clip1_2772_1340)">
          <g clipPath="url(#clip2_2772_1340)">
            <path
              d="M10.6314 15.612C13.4141 15.612 15.6663 13.4622 15.6663 10.806C15.6663 8.14982 13.4141 6 10.6314 6C7.84865 6 5.5964 8.14982 5.5964 10.806C5.5964 13.4622 7.84865 15.612 10.6314 15.612ZM14.0839 16.9851H13.7108C12.7757 17.4142 11.7373 17.6717 10.6314 17.6717C9.52547 17.6717 8.49151 17.4142 7.55195 16.9851H7.17882C4.31968 16.9851 2 19.1993 2 21.9284V23.1643C2 24.3014 2.96653 25.224 4.15784 25.224H17.1049C18.2962 25.224 19.2627 24.3014 19.2627 23.1643V21.9284C19.2627 19.1993 16.9431 16.9851 14.0839 16.9851ZM23.5784 15.612C25.961 15.612 27.8941 13.7668 27.8941 11.4926C27.8941 9.2183 25.961 7.37314 23.5784 7.37314C21.1958 7.37314 19.2627 9.2183 19.2627 11.4926C19.2627 13.7668 21.1958 15.612 23.5784 15.612ZM25.7363 16.9851H25.5654C24.9406 17.1911 24.2797 17.3284 23.5784 17.3284C22.8771 17.3284 22.2163 17.1911 21.5914 16.9851H21.4206C20.5035 16.9851 19.6583 17.2383 18.9166 17.646C20.0135 18.7745 20.7013 20.2721 20.7013 21.9284V23.5762C20.7013 23.6706 20.6788 23.7607 20.6743 23.8508H28.6134C29.8047 23.8508 30.7712 22.9283 30.7712 21.7911C30.7712 19.135 28.519 16.9851 25.7363 16.9851Z"
              fill="black"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2772_1340">
          <rect width="32" height="32" fill="white" />
        </clipPath>
        <clipPath id="clip1_2772_1340">
          <rect width="32" height="32" fill="white" />
        </clipPath>
        <clipPath id="clip2_2772_1340">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
