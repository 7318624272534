import React, { useEffect, useRef, useState } from 'react';

import { CardPlus } from 'components/Icons';
import { DownArrow } from 'components/Icons/DownArrow';
import { PixelportCloseIcon } from 'components/Icons/PixelportCloseIcon';
import { useWindowSize } from 'hooks';
import useOutsideClick from 'hooks/useOutSideClick';
import { Link } from 'react-router-dom';
import { ClassNames } from 'types';
import { classes } from 'utils';

export interface DropdownOption {
  label: string;
  value: string;
  icon?: React.ReactNode;
}

export interface DropdownProps {
  mobileLabel?: string;
  options: (string | DropdownOption)[];
  onSelect?: (selectedOption: string | DropdownOption) => void;
  extraOption?: { label: string; redirectLink: string };
  defaultSelected?: string | DropdownOption;
  placeholder?: string;
  withBorder?: boolean;
  classNames?: ClassNames<
    'container' | 'selector' | 'optionContainer' | 'option' | 'placeHolder' | 'handlerIcon'
  >;
}

export const Dropdown = React.forwardRef(
  (
    {
      options,
      onSelect,
      defaultSelected,
      extraOption,
      placeholder = 'Select Option',
      withBorder = true,
      mobileLabel,
      classNames,
    }: DropdownProps,
    ref
  ) => {
    const impactRef = useRef<HTMLDivElement>(null);
    const [selectedOption, setSelectedOption] = useState<string | DropdownOption | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    useOutsideClick(impactRef, () => setIsOpen(false));
    const {
      windowSize: { width },
    } = useWindowSize();

    useEffect(() => {
      defaultSelected && setSelectedOption(defaultSelected);
    }, [defaultSelected]);

    const handleSelect = (option: string | DropdownOption) => {
      setSelectedOption(option);
      onSelect && onSelect(option);
      closeDropdown();
    };

    const toggleDropdown = () => {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const closeDropdown = () => {
      setIsOpen(false);
    };

    const renderOption = (option: string | DropdownOption) => {
      let isSelected = false;

      if (typeof option === 'string') {
        isSelected = selectedOption === option;
      } else {
        if (
          selectedOption !== null &&
          typeof selectedOption !== 'string' &&
          'value' in selectedOption
        ) {
          isSelected = selectedOption.value === option.value;
        }
      }

      const label = typeof option === 'string' ? option : option.label;
      const icon = typeof option === 'object' && option.icon ? option.icon : null;

      return (
        <button
          key={typeof option === 'string' ? option : option.value}
          onClick={() => handleSelect(option)}
          className={classes(
            'block text-left text-sm text-white hover:text-gray-300 md:border-b border-lightBlack/10 py-[14px] last:border-0',
            classNames?.option
          )}
        >
          <div className="flex items-center gap-[7px] justify-between">
            <div className="flex items-center gap-[7px]">
              {icon && <div className="mr-2">{icon}</div>}
              {label}
            </div>
            {isSelected ? (
              <div className="rounded-full border-[1px] border-lightBlack">
                <div className="p-[7px] bg-lightBlack m-1 rounded-full"></div>
              </div>
            ) : (
              <div className="rounded-full border-[12px] border-lightBlack/5">
                <div className="p-0"></div>
              </div>
            )}
          </div>
        </button>
      );
    };

    const renderSelected = () => {
      if (selectedOption === null) {
        return <span className={classNames?.placeHolder}>{placeholder}</span>;
      } else if (typeof selectedOption === 'string') {
        return <span className={classNames?.placeHolder}>{selectedOption}</span>;
      } else {
        return (
          <div className={classes('flex items-center gap-[7px]', classNames?.placeHolder)}>
            {selectedOption.icon && <div className="mr-2">{selectedOption.icon}</div>}
            {selectedOption.label}
          </div>
        );
      }
    };

    return (
      <>
        {isOpen && width < 768 && (
          <div
            className="fixed inset-0 bg-lightBlack/[0.48] bg-opacity-50 z-40"
            onClick={closeDropdown}
          ></div>
        )}
        <div
          ref={impactRef}
          className={classes('inline-block text-black text-left md:relative', classNames?.base)}
        >
          <button
            type="button"
            onClick={toggleDropdown}
            className={classes(
              'inline-flex justify-center items-center text-sm rounded-[25px] px-[13px] py-2 h-9',
              withBorder && 'shadow-[0px_0px_0px_2px_#ffffff14]',
              classNames?.selector
            )}
            ref={ref as any}
          >
            <div className={classes(selectedOption ? 'text-lightBlack' : 'text-[#696969]')}>
              {renderSelected()}
            </div>
            <div
              className={classes(
                'w-[18px] h-[18px] transition-transform',
                isOpen ? 'transform rotate-180' : 'rotate-0',
                classNames?.handlerIcon
              )}
            >
              <DownArrow />
            </div>
          </button>

          {isOpen && (
            <div
              className={classes(
                'origin-top-right z-50 mt-2 bg-white',
                'fixed bottom-0 left-0 w-full max-h-[50%] rounded-none',
                'md:absolute md:bottom-auto md:left-[-1px] md:w-[calc(100%+2px)] md:max-h-screen md:rounded-xl md:border border-solid border-white/10',
                classNames?.container
              )}
            >
              <div className="md:hidden flex items-center justify-center border-b border-borderLight p-4">
                <div className="text-lightBlack text-base font-medium">{mobileLabel}</div>
                <div className="absolute right-5" onClick={closeDropdown}>
                  <PixelportCloseIcon />
                </div>
              </div>
              <div className={classes('flex flex-col px-4 py-5', classNames?.optionContainer)}>
                {extraOption && (
                  <Link
                    to={extraOption.redirectLink}
                    className="flex justify-between md:pb-4 md:border-b border-lightBlack/10"
                  >
                    <p className="underline md:no-underline text-lightBlack">{extraOption.label}</p>
                    <div className="flex items-center bg-yellow rounded-full p-1.5">
                      <CardPlus className="h-3 w-3" />
                    </div>
                  </Link>
                )}
                {options.map(renderOption)}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);

export default Dropdown;
