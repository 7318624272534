import { Props } from './types';

export function PixelportCloseIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4211 7.1578L9.13274 7.1578L9.13275 4.77393L11.4211 4.77393L11.4211 7.1578ZM6.84435 4.77393L4.55595 4.77393L4.55595 7.1578L6.84435 7.1578L6.84435 9.54168L9.13274 9.54168L9.13274 7.1578L6.84435 7.1578L6.84435 4.77393Z"
        fill="#171717"
      />
      <path
        d="M13.7097 2.38994L11.4213 2.38994L11.4213 4.77382L13.7097 4.77382L13.7097 2.38994Z"
        fill="#171717"
      />
      <path
        d="M16.0001 0.0239258L13.7117 0.0239257L13.7117 2.4078L16.0001 2.4078L16.0001 0.0239258Z"
        fill="#171717"
      />
      <path
        d="M2.26903 2.38965L2.26903 4.77353L4.55786 4.77382L4.55771 2.38965L2.26903 2.38965Z"
        fill="#171717"
      />
      <path
        d="M-6.81877e-05 -3.93909e-08L-6.84566e-05 2.38388L2.28833 2.38388L2.28833 0L-6.81877e-05 -3.93909e-08Z"
        fill="#171717"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.57886 8.8422L6.86726 8.8422L6.86725 11.2261L4.57886 11.2261L4.57886 8.8422ZM9.15565 11.2261L11.4441 11.2261L11.4441 8.8422L9.15565 8.8422L9.15565 6.45832L6.86726 6.45832L6.86726 8.8422L9.15565 8.8422L9.15565 11.2261Z"
        fill="#171717"
      />
      <path
        d="M2.29032 13.6101L4.57872 13.6101L4.57872 11.2262L2.29032 11.2262L2.29032 13.6101Z"
        fill="#171717"
      />
      <path
        d="M-0.000103813 15.9761L2.28829 15.9761L2.28829 13.5922L-0.000103681 13.5922L-0.000103813 15.9761Z"
        fill="#171717"
      />
      <path
        d="M13.731 13.6104L13.731 11.2265L11.4421 11.2262L11.4423 13.6104L13.731 13.6104Z"
        fill="#171717"
      />
      <path d="M16.0001 16L16.0001 13.6161L13.7117 13.6161L13.7117 16L16.0001 16Z" fill="#171717" />
    </svg>
  );
}
