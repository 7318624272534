import { ClassNames, HTMLAttributes } from 'types';
import { classes } from 'utils';

type Variant = 'primary' | 'plain';

interface Props$Button extends HTMLAttributes<HTMLButtonElement> {
  isDisabled?: boolean;
  isIcon?: boolean;
  isLoading?: boolean;
  variant?: Variant;
  classNames?: ClassNames<'container'>;
}

export const OldButton = (props: Props$Button) => {
  const {
    children,
    variant = 'primary',
    className,
    isDisabled,
    isIcon,
    classNames,
    isLoading,
    ...rest
  } = props;

  return (
    <button
      type="button"
      className={classes(
        'button',
        isIcon && '!pl-1 !md:pr-6 pr-4 flex ',
        variant,
        className,
        classNames?.base
      )}
      disabled={isDisabled || isLoading}
      {...rest}
    >
      <div
        className={classes(
          'flex items-center justify-center whitespace-pre text-sm lg:text-lg btnText h-full',
          isIcon && 'md:gap-[18px] gap-3',
          classNames?.container
        )}
      >
        {isLoading && <div className="loading-spinner" />}
        {isLoading ? <div className="opacity-0">{children}</div> : children}
      </div>
    </button>
  );
};

OldButton.displayName = 'Button';

export default OldButton;
