import { useRef } from 'react';
import './pixelport.css';

import { useGSAP } from '@gsap/react';
import { Footer, Navbar } from 'components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useWindowSize } from 'hooks';

import { HeroSection } from './HeroSection';
import { HowItWorks } from './HowItWorks';
import { KeyFeatures } from './KeyFeatures';
import { UseCases } from './UseCases';

gsap.registerPlugin(useGSAP, ScrollTrigger);

export const Landing = () => {
  const plane = useRef<HTMLDivElement | null>(null);
  const cloud = useRef<HTMLDivElement | null>(null);
  const multiPlaneRef = useRef<HTMLDivElement | null>(null);
  const pilotRef = useRef<HTMLDivElement | null>(null);

  const {
    windowSize: { width },
  } = useWindowSize();

  const isBelowMd = width < 768;
  const isBelow2Xl = width < 1536;

  useGSAP(
    () => {
      const planes = gsap.utils.toArray('.plane') as HTMLElement[];
      planes.forEach((plane) => {
        gsap.to(plane, {
          x: isBelowMd ? 100 : 350,
          scrollTrigger: {
            trigger: plane,
            start: 'top top',
            end: 'bottom 1%',
            scrub: true,
          },
        });
      });
    },
    { scope: plane }
  );

  useGSAP(
    () => {
      const clouds = gsap.utils.toArray('.whiteCloud') as HTMLElement[];
      clouds.forEach((cloud) => {
        gsap.to(cloud, {
          y: -100,
          scrollTrigger: {
            trigger: cloud,
            start: 'top 85%',
            end: 'top 10%',
            scrub: true,
          },
        });
      });
    },
    { scope: cloud }
  );

  useGSAP(
    () => {
      const multiPlanes = gsap.utils.toArray('.multiPlanes') as HTMLElement[];
      multiPlanes.forEach((multiPlane) => {
        gsap.to(multiPlane, {
          x: isBelowMd ? 100 : 800,
          scrollTrigger: {
            trigger: multiPlane,
            start: 'top 100%',
            end: 'bottom 1%',
            scrub: true,
          },
        });
      });
    },
    { scope: multiPlaneRef }
  );

  useGSAP(
    () => {
      const pilots = gsap.utils.toArray('.pilot') as HTMLElement[];
      pilots.forEach((pilot) => {
        gsap.to(pilot, {
          x: 400,
          scrollTrigger: {
            trigger: pilot,
            start: 'top 100%',
            end: 'bottom 1%',
            scrub: true,
          },
        });
      });
    },
    { scope: pilotRef }
  );

  return (
    <>
      <div className={`w-full mx-auto`} ref={cloud}>
        <Navbar />
        <div ref={plane}>
          <img
            src="./newDesign/leftFactory.png"
            alt="left-factory"
            className="absolute -top-0 -left-30 hidden md:flex md:h-[700px] 2xl:h-auto"
            loading="lazy"
          />
          <div className="plane">
            <img
              src="./newDesign/plane.png"
              alt="plane"
              className="z-10 w-[157px] md:w-[270px] 2xl:w-[339px] relative top-[58px] ml-[9%] md:ml-[29%] left-auto"
              loading="lazy"
            />
          </div>
          <img
            src="./newDesign/rightFactory.png"
            alt="right-factory"
            className="absolute -right-[60px] md:right-[-170px] top-[380px] md:top-[121px] h-[280px] md:h-[766px]"
            loading="lazy"
          />
        </div>
        <HeroSection />
        <div className="relative md:mt-[-60px]">
          <img
            src="./newDesign/whiteClouds.png"
            alt="white-clouds"
            className="w-full mx-auto relative -top-[100px] md:top-[-250px]"
            loading="lazy"
          />
          <img
            src="./newDesign/whiteClouds.png"
            alt="white-clouds"
            className="whiteCloud w-full absolute -top-[0px] md:top-[-150px]"
            loading="lazy"
          />
        </div>
        <div className="relative">
          <div
            className="flex container mx-auto max-w-[1145px] -mt-[27%] relative"
            ref={multiPlaneRef}
          >
            <KeyFeatures />
            <div className="multiPlanes absolute top-44">
              <img
                src="./newDesign/multiPlane.png"
                alt="multiPlane"
                className="h-[220px]"
                loading="lazy"
              />
            </div>
          </div>

          <div ref={pilotRef}>
            <div className="pilot absolute z-[6] top-[30%] md:top-[30%] 2xl:top-[17%] -left-[180px]">
              <img src="./newDesign/pilot.png" alt="pilot" className="h-[250px]" loading="lazy" />
            </div>
            <div className="pilot absolute z-[6] top-[32%] md:top-[32%] 2xl:top-[19%] -left-[480px]">
              <img src="./newDesign/pilot.png" alt="pilot" className="h-[350px]" loading="lazy" />
            </div>
          </div>
          <img
            src="/newDesign/mountainCity.png"
            alt="mountainCity"
            className="absolute w-full top-[1940px] md:top-[38%] 2xl:top-[25%] z-[5]"
            loading="lazy"
          />
        </div>

        <div
          className="mt-[200px] md:mt-[-200px] md:pt-[30rem] z-[4] relative"
          style={{
            background: 'url(/newDesign/cave.png)',
            backgroundRepeat: 'no-repeat',
            objectFit: 'cover',
            backgroundSize: isBelowMd ? '130%' : '100%',
          }}
        >
          <UseCases />
        </div>

        <div className="relative">
          <div
            className="-mt-[100px] md:mt-[-350px] pt-[8rem] md:pt-[18rem] 2xl:pt-[30rem] relative z-[3]"
            style={{
              background: 'url(/newDesign/underWater.png)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: isBelowMd ? '500%' : isBelow2Xl ? '200%' : '120%',
            }}
          >
            <div className="flex container mx-auto max-w-[1145px] relative">
              <HowItWorks />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
