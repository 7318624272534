import { Header, TypeFormButton } from 'components';
import { ContactsIcon, FramesIcon, TransferNFTIcon } from 'components/Icons';
import { useWindowSize } from 'hooks';
import { classes } from 'utils';

import { GetStartedButton } from './GetStartedButton';

const FeaturesData = [
  {
    text: 'Mint Cross-Chain NFTs',
    subText:
      'With Pixelmint, you can mint cross-chain NFTs or supercharge your existing ones with cross-chain features - There are no boundaries! It’s fast, fun, and totally secure. Why stay on one chain when you can own them all?',
    Icon: ContactsIcon,
    bgImage: 'newDesign/GIFs/mintCrossChain.gif',
  },
  {
    text: 'Fractionalize NFTs',
    subText:
      "Turn your NFTs into bite-sized investments with Pixelport. Fractionalize and make them accessible to everyone. Whether you're looking to share ownership or just want to make your assets more liquid, Pixelport gives you tools to maximize your NFT's potential.",
    Icon: FramesIcon,
    bgImage: 'newDesign/GIFs/fractionalizeNFTs.gif',
  },
  {
    text: 'Transfer NFTs Cross-Chain',
    subText:
      'Why keep your NFTs locked in the blockchain silos? Pixelport allows you to break down the walls and move your assets across any supported blockchain tapping into new markets and liquidity. It’s your collection—get it where it can do the most good and make the biggest impact',
    Icon: TransferNFTIcon,
    bgImage: 'newDesign/GIFs/transferCrossChain.gif',
  },
];

// eslint-disable-next-line import/no-unused-modules
export function KeyFeatures() {
  const {
    windowSize: { width },
  } = useWindowSize();

  const isBelowMd = width < 768;

  return (
    <div
      className="lg:flex flex-col md:flex-row lg:gap-[78px] md:gap-[30px] gap-8 items-start md:justify-between"
      id="key-features"
    >
      <div className="flex flex-col gap-2 md:gap-3 lg:gap-8 max-w-[550px] mt-16 md:sticky top-[5%] md:top-[28%] z-10 px-10 pb-8">
        <Header
          classNames={{
            header:
              'text-5xl md:text-[90px] lg:min-w-[600px] leading-[48px] font-["Arcade"] normal-case pixelport-text-shadow uppercase',
          }}
        >
          Key Features
        </Header>
        <Header
          tag={'p'}
          classNames={{
            header: 'text-xs md:text-lg normal-case font-Jakarta font-normal',
          }}
        >
          At Pixelport, we're making NFT creation and trading as fun and accessible as your favorite
          game. With cross-chain capabilities and fractionalization, Pixelport empowers you to
          unlock endless possibilities across any blockchain effortlessly. Ready to play?"
        </Header>
        <TypeFormButton formId="uvT7QzSD" as="div" className="mt-4">
          <GetStartedButton
            title="get early access"
            arrowStroke="black"
            classNames={{
              base: '!bg-black !text-white z-[100] relative',
              container:
                'bg-white lg:group-hover:translate-x-[200px] sm:group-hover:translate-x-[165px] ',
            }}
          />
        </TypeFormButton>
      </div>
      <div
        className={`grid grid-rows-[repeat(${FeaturesData.length},_40vw)] gap-[42px] px-8 mt-4 pb-8`}
      >
        {FeaturesData.map(({ subText, text, Icon, bgImage }, index) => {
          return (
            <div
              key={`feature-${index}`}
              className={classes(
                'md:w-[500px] min-h-[544px] md:min-h-[644px] rounded-lg px-6 md:px-[50px] py-[20px] md:py-[38px] md:backdrop-blur-[80px] shadow-navbar-shadow border border-[#83838333] bg-[#ffffff] md:bg-[#ffffff7d]',
                'sticky z-10'
              )}
              style={{
                top: isBelowMd ? `${10 + index * 68}px` : `${10 + index * 100}px`,
                backgroundImage: `url(./${bgImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
                backgroundPositionY: '100%',
                backgroundSize: '100%',
              }}
            >
              <div className="flex gap-[21px] pb-[30px]">
                <Icon />
                <div className="text-lg md:text-2xl font-semibold font-Jakarta">{text}</div>
              </div>
              <div className="text-[13px] md:text-lg font-Jakarta font-normal">{subText}</div>
            </div>
          );
        })}
        <div className="py-2"></div>
      </div>
    </div>
  );
}
