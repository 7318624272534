import { HTMLAttributes } from 'types';

export function RightArrowIcon(props: HTMLAttributes<HTMLOrSVGElement> & { stroke?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <path
        d="M3.92773 11H18.0706"
        stroke={props.stroke || '#171717'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2852 5.21436L18.0709 11.0001L12.2852 16.7858"
        stroke={props.stroke || '#171717'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LeftArrowIcon(props: HTMLAttributes<HTMLOrSVGElement> & { stroke?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M3.70216 9.02154V10.7236H5.40431V9.02154H14V7.31935H5.40431V5.61719H3.70216V7.31935H2V9.02154H3.70216Z"
        fill={'#999999'}
      />
      <path d="M7.39161 12.211V10.5088H5.68945V12.211H7.39161Z" fill="#999999" />
      <path d="M9.09082 13.914V12.2119H7.38867V13.914H9.09082Z" fill="#999999" />
      <path d="M7.39161 3.70215H5.68945V5.40431H7.39161V3.70215Z" fill="#999999" />
      <path d="M9.09082 2H7.38867V3.70215H9.09082V2Z" fill="#999999" />
    </svg>
  );
}

export function PlayVideoIcon(props: HTMLAttributes<HTMLOrSVGElement> & { stroke?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M12.1338 16V14.0267C12.1338 11.48 13.9338 10.4533 16.1338 11.72L17.8405 12.7067L19.5471 13.6933C21.7471 14.96 21.7471 17.04 19.5471 18.3067L17.8405 19.2933L16.1338 20.28C13.9338 21.5467 12.1338 20.5067 12.1338 17.9733V16Z"
        fill={props.stroke || '#171717'}
        stroke={props.stroke || '#171717'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0003 29.3334C23.3641 29.3334 29.3337 23.3639 29.3337 16.0001C29.3337 8.63628 23.3641 2.66675 16.0003 2.66675C8.63653 2.66675 2.66699 8.63628 2.66699 16.0001C2.66699 23.3639 8.63653 29.3334 16.0003 29.3334Z"
        stroke={props.stroke || '#171717'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SDKICon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.85759 0.941696V1.88339H0.928793H0V13.023V24.1625H0.928793H1.85759V25.0813V26H15H28.1424V25.0813V24.1625H29.0712H30V13.1378V2.11307H29.0712H28.1424V1.05654V0H15H1.85759V0.941696ZM7.47678 5.60424V7.4417H6.57121H5.66563V5.60424V3.76678H6.57121H7.47678V5.60424ZM13.096 5.60424V7.4417H12.1904H11.2848V5.60424V3.76678H12.1904H13.096V5.60424ZM26.2384 15.7792V22.2332H15H3.76161V15.7792V9.32509H15H26.2384V15.7792ZM5.6192 14.8375V18.5583H7.5H9.38081V14.8375V11.1166H7.5H5.6192V14.8375Z"
        fill="#171717"
      />
    </svg>
  );
}

export function Danger(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      {...props}
    >
      <path
        d="M27.2001 19.9L19.2001 5.5C18.1251 3.5625 16.6376 2.5 15.0001 2.5C13.3626 2.5 11.8751 3.5625 10.8001 5.5L2.80009 19.9C1.78759 21.7375 1.67509 23.5 2.48759 24.8875C3.30009 26.275 4.90009 27.0375 7.00009 27.0375H23.0001C25.1001 27.0375 26.7001 26.275 27.5126 24.8875C28.3251 23.5 28.2126 21.725 27.2001 19.9ZM14.0626 11.25C14.0626 10.7375 14.4876 10.3125 15.0001 10.3125C15.5126 10.3125 15.9376 10.7375 15.9376 11.25V17.5C15.9376 18.0125 15.5126 18.4375 15.0001 18.4375C14.4876 18.4375 14.0626 18.0125 14.0626 17.5V11.25ZM15.8876 22.1375C15.8251 22.1875 15.7626 22.2375 15.7001 22.2875C15.6251 22.3375 15.5501 22.375 15.4751 22.4C15.4001 22.4375 15.3251 22.4625 15.2376 22.475C15.1626 22.4875 15.0751 22.5 15.0001 22.5C14.9251 22.5 14.8376 22.4875 14.7501 22.475C14.6751 22.4625 14.6001 22.4375 14.5251 22.4C14.4501 22.375 14.3751 22.3375 14.3001 22.2875C14.2376 22.2375 14.1751 22.1875 14.1126 22.1375C13.8876 21.9 13.7501 21.575 13.7501 21.25C13.7501 20.925 13.8876 20.6 14.1126 20.3625C14.1751 20.3125 14.2376 20.2625 14.3001 20.2125C14.3751 20.1625 14.4501 20.125 14.5251 20.1C14.6001 20.0625 14.6751 20.0375 14.7501 20.025C14.9126 19.9875 15.0876 19.9875 15.2376 20.025C15.3251 20.0375 15.4001 20.0625 15.4751 20.1C15.5501 20.125 15.6251 20.1625 15.7001 20.2125C15.7626 20.2625 15.8251 20.3125 15.8876 20.3625C16.1126 20.6 16.2501 20.925 16.2501 21.25C16.2501 21.575 16.1126 21.9 15.8876 22.1375Z"
        fill="url(#paint0_linear_570_10292)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_570_10292"
          x1="15.0001"
          y1="2.5"
          x2="15.0001"
          y2="27.0375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#171717" stopOpacity="0.85" />
          <stop offset="1" stopColor="#171717" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function PaintIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        d="M14 2.33301C20.4426 2.33301 25.6667 6.97375 25.6667 12.7034C25.6667 16.2812 22.763 19.1848 19.1852 19.1848H16.8908C15.8149 19.1848 14.9463 20.0534 14.9463 21.1293C14.9463 21.6219 15.1408 22.0756 15.4389 22.4126C15.75 22.7626 15.9445 23.2163 15.9445 23.7219C15.9445 24.7979 15.05 25.6663 14 25.6663C7.55745 25.6663 2.33337 20.4422 2.33337 13.9997C2.33337 7.55708 7.55745 2.33301 14 2.33301ZM8.75004 13.9997C9.71654 13.9997 10.5 13.2161 10.5 12.2497C10.5 11.2832 9.71654 10.4997 8.75004 10.4997C7.78354 10.4997 7.00004 11.2832 7.00004 12.2497C7.00004 13.2161 7.78354 13.9997 8.75004 13.9997ZM19.25 13.9997C20.2165 13.9997 21 13.2161 21 12.2497C21 11.2832 20.2165 10.4997 19.25 10.4997C18.2836 10.4997 17.5 11.2832 17.5 12.2497C17.5 13.2161 18.2836 13.9997 19.25 13.9997ZM14 10.4997C14.9665 10.4997 15.75 9.71618 15.75 8.74967C15.75 7.78317 14.9665 6.99967 14 6.99967C13.0336 6.99967 12.25 7.78317 12.25 8.74967C12.25 9.71618 13.0336 10.4997 14 10.4997Z"
        fill="#171717"
      />
    </svg>
  );
}

export function HandShake(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        d="M22.5067 7.00025H23.3336C23.978 7.00025 24.5003 7.52259 24.5003 8.16692V16.3335C24.5003 16.9779 23.978 17.5002 23.3336 17.5002H21.0003L16.1373 10.692C15.557 9.87962 14.5242 9.52641 13.568 9.81326L10.6686 10.6831C9.84637 10.9298 8.95514 10.7051 8.34816 10.0981L8.00661 9.75654C7.48602 9.23596 7.57184 8.36924 8.18441 7.96086L14.4856 3.76005C15.2544 3.24754 16.2531 3.23691 17.0327 3.73296L21.8804 6.81786C22.0675 6.93699 22.2849 7.00025 22.5067 7.00025ZM5.86497 16.6775L3.97991 18.3269C3.42994 18.808 3.45273 19.6707 4.02739 20.1223L10.0249 24.8346C10.585 25.2746 11.4053 25.1145 11.7586 24.496L12.5799 23.0589C13.0815 22.181 12.9607 21.0799 12.2805 20.3317L9.12801 16.8639C8.27277 15.9231 6.8218 15.8403 5.86497 16.6775ZM8.21987 5.83366H3.50004C2.85571 5.83366 2.33337 6.356 2.33337 7.00032V15.7688C2.33337 16.2452 2.47892 16.7038 2.74193 17.0878C2.76982 17.0614 2.79836 17.0354 2.82754 17.0099L4.71259 15.3604C6.38704 13.8953 8.92624 14.0403 10.4229 15.6867L13.5755 19.1545C14.7657 20.4638 14.9772 22.3908 14.0993 23.9271L13.336 25.2631C13.9927 25.5119 14.7409 25.4558 15.3639 25.082L20.5079 21.9956C21.0917 21.6453 21.2527 20.8715 20.8572 20.3174L14.7128 11.7091C14.5676 11.5061 14.3094 11.4177 14.0704 11.4895L11.1709 12.3593C9.73209 12.791 8.17245 12.3978 7.11024 11.3355L6.76868 10.994C5.46722 9.69251 5.68177 7.52573 7.2132 6.50477L8.21987 5.83366Z"
        fill="#171717"
      />
    </svg>
  );
}

export function GamingIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        d="M19.8334 4.66699C23.6994 4.66699 26.8334 7.801 26.8334 11.667V16.3337C26.8334 20.1996 23.6994 23.3337 19.8334 23.3337H8.16675C4.30075 23.3337 1.16675 20.1996 1.16675 16.3337V11.667C1.16675 7.801 4.30075 4.66699 8.16675 4.66699H19.8334ZM11.6667 10.5003H9.33342V12.8337H7.00008V15.167H9.33225L9.33342 17.5003H11.6667L11.6656 15.167H14.0001V12.8337H11.6667V10.5003ZM21.0001 15.167H18.6668V17.5003H21.0001V15.167ZM18.6668 10.5003H16.3334V12.8337H18.6668V10.5003Z"
        fill="#171717"
      />
    </svg>
  );
}

export function Music(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1668 10.6988C14.8019 10.5698 14.4092 10.4997 14.0002 10.4997C12.0671 10.4997 10.5002 12.0666 10.5002 13.9997C10.5002 15.9327 12.0671 17.4997 14.0002 17.4997C15.9332 17.4997 17.5002 15.9327 17.5002 13.9997V2.86711C22.2334 4.35376 25.6668 8.77576 25.6668 13.9997C25.6668 20.4429 20.4434 25.6663 14.0002 25.6663C7.55684 25.6663 2.3335 20.4429 2.3335 13.9997C2.3335 7.55635 7.55684 2.33301 14.0002 2.33301C14.3939 2.33301 14.7831 2.35251 15.1668 2.39062V10.6988Z"
        fill="#171717"
      />
    </svg>
  );
}

export function DeFi(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.0752 2.6748L18.4252 4.32472L21.1002 6.99977H15.1669V9.33309H21.1002L18.4252 12.0081L20.0752 13.658L25.5668 8.16642L20.0752 2.6748ZM8.16684 13.4164C11.0663 13.4164 13.4169 11.0659 13.4169 8.16642C13.4169 5.26693 11.0663 2.91642 8.16684 2.91642C5.26735 2.91642 2.91684 5.26693 2.91684 8.16642C2.91684 11.0659 5.26735 13.4164 8.16684 13.4164ZM6.90008 20.9997L9.57513 23.6748L7.92521 25.3247L2.43359 19.8331L7.92521 14.3415L9.57513 15.9914L6.90008 18.6664H12.8336V20.9997H6.90008ZM16.3336 15.1664C15.6892 15.1664 15.1669 15.6887 15.1669 16.3331V23.3331C15.1669 23.9774 15.6892 24.4997 16.3336 24.4997H23.3336C23.9778 24.4997 24.5002 23.9774 24.5002 23.3331V16.3331C24.5002 15.6887 23.9778 15.1664 23.3336 15.1664H16.3336Z"
        fill="#171717"
      />
    </svg>
  );
}

export function Sports(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4269 15.5933L16.4974 16.7893C15.2249 19.6039 15.1611 22.7111 16.1345 25.4696C15.4437 25.5994 14.7297 25.6663 13.9999 25.6663C12.2911 25.6663 10.6681 25.299 9.20555 24.6389L14.4269 15.5933ZM18.5307 17.9635L23.4695 20.8157C22.1927 22.5865 20.4315 23.9847 18.3799 24.8163C17.6221 22.7625 17.5881 20.4569 18.3894 18.3183L18.5307 17.9635ZM10.3353 13.2317L12.4063 14.4267L7.18394 23.4693C5.27255 22.0911 3.79519 20.1485 2.99607 17.8856C5.74811 17.3741 8.30167 15.8798 10.0955 13.5539L10.3353 13.2317ZM25.5559 12.3856C25.6289 12.9132 25.6666 13.452 25.6666 13.9997C25.6666 15.7085 25.2992 17.3316 24.6391 18.7941L19.6977 15.9421C21.1232 14.0577 23.167 12.8421 25.3746 12.4172L25.5559 12.3856ZM3.36074 9.20531L8.30178 12.0579C6.83709 13.9937 4.72002 15.2236 2.44396 15.616C2.37104 15.087 2.33325 14.5478 2.33325 13.9997C2.33325 12.2909 2.70064 10.6678 3.36074 9.20531ZM20.8159 4.53005C22.7269 5.908 24.2042 7.85019 25.0033 10.1125C22.2507 10.6257 19.6975 12.1203 17.9039 14.446L17.6641 14.7683L15.5936 13.5727L20.8159 4.53005ZM13.9999 2.33301C15.7087 2.33301 17.3318 2.70039 18.7943 3.36049L13.5729 12.406L11.502 11.2111C12.7749 8.39621 12.8389 5.28855 11.8654 2.52957C12.5562 2.4 13.2702 2.33301 13.9999 2.33301ZM9.62117 3.18393C10.378 5.23737 10.4118 7.54328 9.6101 9.68208L9.46874 10.0369L4.5303 7.18369C5.75871 5.48002 7.43554 4.12118 9.38788 3.28007L9.62117 3.18393Z"
        fill="#171717"
      />
    </svg>
  );
}

export function Crowdfunding(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.0056 25.6702C7.56222 25.6702 2.33887 20.4469 2.33887 14.0035C2.33887 7.56026 7.56222 2.33691 14.0056 2.33691C20.4488 2.33691 25.6722 7.56026 25.6722 14.0035C25.6722 20.4469 20.4488 25.6702 14.0056 25.6702ZM9.9222 16.3369V18.6702H12.8389V21.0035H15.1722V18.6702H16.3389C17.9497 18.6702 19.2556 17.3645 19.2556 15.7535C19.2556 14.1427 17.9497 12.8369 16.3389 12.8369H11.6722C11.35 12.8369 11.0889 12.5758 11.0889 12.2535C11.0889 11.9314 11.35 11.6702 11.6722 11.6702H18.0889V9.33692H15.1722V7.00358H12.8389V9.33692H11.6722C10.0614 9.33692 8.75553 10.6427 8.75553 12.2535C8.75553 13.8645 10.0614 15.1702 11.6722 15.1702H16.3389C16.661 15.1702 16.9222 15.4314 16.9222 15.7535C16.9222 16.0758 16.661 16.3369 16.3389 16.3369H9.9222Z"
        fill="#171717"
      />
    </svg>
  );
}

export function Governance(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.33317 22.1667V9.33333H1.1665V7H4.6665V4.66667C4.6665 4.02234 5.18884 3.5 5.83317 3.5H22.1665C22.8109 3.5 23.3332 4.02234 23.3332 4.66667V7H26.8332V9.33333H25.6665V22.1667H26.8332V24.5H1.1665V22.1667H2.33317ZM15.1665 22.1667V14H12.8332V22.1667H15.1665ZM9.33317 22.1667V14H6.99984V22.1667H9.33317ZM20.9998 22.1667V14H18.6665V22.1667H20.9998ZM6.99984 5.83333V7H20.9998V5.83333H6.99984Z"
        fill="#171717"
      />
    </svg>
  );
}
