import { tv } from 'tailwind-variants';

export const tvButtonStyle = tv({
  base: 'button py-3 px-8 rounded-full font-NeueBit text-[22px] uppercase transition duration-200 ease-in-out disabled:opacity-50 max-h-12 focus-visible:outline-0 focus-visible:ring-0',
  variants: {
    variant: {
      contained: '',
      outlined: '',
    },
    color: {
      white: '',
      black: '',
      yellow: '',
    },
    size: {
      sm: 'md:text-[22px] text-[20px]',
      md: 'md:text-[22px] text-[20px]',
      lg: 'md:text-[22px] text-[20px]',
    },
    disabled: {
      true: 'cursor-not-allowed',
    },
    isLoading: {
      true: 'pl-6',
    },
    startIcon: {
      true: 'pl-6',
    },
    endIcon: {
      true: 'pr-6',
    },
  },
  compoundVariants: [
    {
      color: 'black',
      variant: 'contained',
      class: 'bg-lightBlack text-white hover:enabled:bg-opacity-80 active:enabled:bg-opacity-70 ',
    },
    {
      color: 'black',
      variant: 'outlined',
      class:
        'border border-lightBlack bg-white text-lightBlack hover:enabled:bg-lightBlack hover:enabled:text-white active:enabled:bg-opacity-70 disabled:opacity-50',
    },
    {
      color: 'white',
      variant: 'contained',
      class: 'bg-white text-lightBlack hover:enabled:bg-opacity-80 active:enabled:bg-opacity-70',
    },
    {
      color: 'white',
      variant: 'outlined',
      class:
        'border border-white text-white hover:enabled:bg-white hover:enabled:text-lightBlack active:enabled:bg-opacity-70',
    },
    {
      color: 'yellow',
      variant: 'contained',
      class: 'bg-yellow text-lightBlack hover:enabled:bg-opacity-50 active:enabled:bg-opacity-60',
    },
    {
      color: 'yellow',
      variant: 'outlined',
      class:
        'border border-yellow text-yellow  hover:enabled:bg-yellow hover:enabled:bg-opacity-80 hover:enabled:text-lightBlack active:enabled:bg-opacity-70',
    },
  ],
  defaultVariants: {
    size: 'md',
    variant: 'contained',
    color: 'yellow',
    disabled: false,
  },
});
