import { Props } from './types';

export function ToastErrorIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <rect width="32" height="32" rx="16" fill="#FF0000" />
      <g clipPath="url(#clip0_764_109375)">
        <path
          d="M17.3334 16.6667H18V17.3334H18.6667V18.0001H19.3334V18.6667H18.6667V19.3334H18V18.6667H17.3334V18.0001H16.6667V17.3334H15.3334V18.0001H14.6667V18.6667H14V19.3334H13.3334V18.6667H12.6667V18.0001H13.3334V17.3334H14V16.6667H14.6667V15.3334H14V14.6667H13.3334V14.0001H12.6667V13.3334H13.3334V12.6667H14V13.3334H14.6667V14.0001H15.3334V14.6667H16.6667V14.0001H17.3334V13.3334H18V12.6667H18.6667V13.3334H19.3334V14.0001H18.6667V14.6667H18V15.3334H17.3334V16.6667Z"
          fill="white"
        />
        <path
          d="M22.6667 14.0001V12.6667H22V11.3334H21.3334V10.6667H20.6667V10.0001H19.3334V9.33341H18V8.66675H14V9.33341H12.6667V10.0001H11.3334V10.6667H10.6667V11.3334H10V12.6667H9.33335V14.0001H8.66669V18.0001H9.33335V19.3334H10V20.6667H10.6667V21.3334H11.3334V22.0001H12.6667V22.6667H14V23.3334H18V22.6667H19.3334V22.0001H20.6667V21.3334H21.3334V20.6667H22V19.3334H22.6667V18.0001H23.3334V14.0001H22.6667ZM22 18.0001H21.3334V19.3334H20.6667V20.6667H19.3334V21.3334H18V22.0001H14V21.3334H12.6667V20.6667H11.3334V19.3334H10.6667V18.0001H10V14.0001H10.6667V12.6667H11.3334V11.3334H12.6667V10.6667H14V10.0001H18V10.6667H19.3334V11.3334H20.6667V12.6667H21.3334V14.0001H22V18.0001Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_764_109375">
          <rect width="16" height="16" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
}
