import { Props } from './types';

export const RoundTickBlack = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={24} fill="none" {...props}>
    <path
      fill="#000"
      d="M13.263 0C6.055 0 .211 5.373.211 12s5.844 12 13.052 12c7.209 0 13.052-5.373 13.052-12S20.472 0 13.263 0Zm0 2.323C19.08 2.323 23.79 6.65 23.79 12c0 5.348-4.708 9.677-10.526 9.677-5.817 0-10.526-4.328-10.526-9.677 0-5.348 4.708-9.677 10.526-9.677Zm7.38 6.303-1.187-1.1a.671.671 0 0 0-.893-.003l-7.44 6.785-3.146-2.917a.671.671 0 0 0-.893-.003l-1.196 1.09a.548.548 0 0 0-.004.822l4.778 4.428c.246.227.646.229.893.003l9.084-8.284a.548.548 0 0 0 .003-.821Z"
    />
  </svg>
);

export default RoundTickBlack;
