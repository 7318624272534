import { useState } from 'react';

import { OldButton } from 'components';
import { RightArrowIcon } from 'components/Icons';
import { ClassNames } from 'types';
import { classes } from 'utils';

// eslint-disable-next-line import/no-unused-modules
export function GetStartedButton({
  isDarkTheme,
  title,
  classNames,
  arrowStroke,
}: {
  isDarkTheme?: boolean;
  title: string;
  classNames?: ClassNames<'container' | 'text'>;
  arrowStroke?: string;
}) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <OldButton
      variant="primary"
      className={classes(
        'group transition-all hover:flex-row-reverse hover:text-lightBlack hover:bg-yellow !hover:md:pl-6 !md:pr-1',
        classNames?.base
      )}
      isIcon
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      classNames={{ container: 'gap-5' }}
    >
      <span
        className={classes(
          'w-8 h-8 lg:w-[48px] lg:h-[48px] rounded-full flex items-center translate-x-0 justify-center bg-yellow transition-all sm:group-hover:translate-x-[165px] group-hover:translate-x-[140px] group-hover:bg-lightBlack',
          isDarkTheme && 'md:group-hover:translate-x-[173px] group-hover:translate-x-full',
          classNames?.container
        )}
      >
        <RightArrowIcon stroke={isHovering ? '#FFF' : arrowStroke} />
      </span>
      <span
        className={classes(
          'transition-all group-hover:translate-x-[-30px] lg:group-hover:translate-x-[-50px]',
          classNames?.text
        )}
      >
        {title}
      </span>
    </OldButton>
  );
}
