import { Props } from './types';

export const DoubleRightArrow = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
    <path
      fill="#000"
      d="m16.404 17.198-9.817 9.37a1.784 1.784 0 0 1-2.447 0L2.51 25.013a1.595 1.595 0 0 1 0-2.336l6.958-6.642L2.51 9.392a1.594 1.594 0 0 1 0-2.335l1.624-1.571a1.784 1.784 0 0 1 2.447 0l9.816 9.37c.686.648.686 1.695.008 2.342Zm13.858-2.342-9.816-9.37a1.784 1.784 0 0 0-2.447 0l-1.631 1.557a1.594 1.594 0 0 0 0 2.335l6.958 6.642-6.958 6.642a1.594 1.594 0 0 0 0 2.336l1.63 1.557a1.784 1.784 0 0 0 2.448 0l9.816-9.37a1.587 1.587 0 0 0 0-2.33Z"
    />
  </svg>
);

export default DoubleRightArrow;
