import { Props } from './types';

export function DownArrow(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8516 11.5594L10.9445 11.5594L10.9445 9.65234L12.8516 9.65234L12.8516 11.5594ZM9.03738 9.65234L7.13029 9.65234L7.13029 11.5594L9.03738 11.5594L9.03738 13.4665L10.9445 13.4665L10.9445 11.5594L9.03738 11.5594L9.03738 9.65234Z"
        fill="#171717"
      />
      <path
        d="M14.7586 7.74535L12.8515 7.74535L12.8515 9.65244L14.7586 9.65244L14.7586 7.74535Z"
        fill="#171717"
      />
      <path
        d="M16.6671 5.85254L14.76 5.85254L14.76 7.75963L16.6671 7.75963L16.6671 5.85254Z"
        fill="#171717"
      />
      <path
        d="M5.22439 7.74512L5.22439 9.65221L7.13184 9.65244L7.13171 7.74512L5.22439 7.74512Z"
        fill="#171717"
      />
      <path
        d="M3.33314 5.83325L3.33314 7.74034L5.24023 7.74034L5.24023 5.83325L3.33314 5.83325Z"
        fill="#171717"
      />
    </svg>
  );
}
