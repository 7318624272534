import { Chip, Header } from 'components';
import { useWindowSize } from 'hooks';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import AdvantageTabs from './components/AdvantageTabs';

const advantages = [
  {
    text: 'Security',
    menu: [
      {
        title: 'Decentralised Security Protocols',
        desc: 'Your real estate assets are guarded by a decentralized fortress across multiple blockchains. By leveraging the best of each network, we reduce vulnerabilities and make sure your assets stay safe from potential threats, like a digital neighborhood watch on steroids',
      },
      {
        title: 'Smart Contract Integrity',
        desc: 'Pixelport’s smart contracts are like the digital notaries you didn’t know you needed—automatically enforcing the terms of your real estate deals with zero room for fraud. Everything runs transparently and reliably so you can skip the legalese and trust the tech.',
      },
      {
        title: 'Cross Chain Verification',
        desc: 'With cross-chain verification, your real estate holdings are recognized and validated across all supported blockchains. It’s like having a universal deed that’s acknowledged everywhere, eliminating any discrepancies and ensuring your ownership is rock-solid.',
      },
    ],
  },
  {
    text: 'Trade',
    menu: [
      {
        title: 'Cross-Chain Asset Transfers',
        desc: 'Smoothly transfer real estate assets across different blockchain networks. This cross-chain functionality offers you the freedom to trade properties without being tied down to one blockchain—your assets, your rules.',
      },
      {
        title: 'Broader Market Access',
        desc: 'Think globally, trade digitally. Access a worldwide market of real estate investors and buyers across multiple blockchains. Pixelport helps you break through those pesky geographical and blockchain barriers connecting you with the right opportunities faster.',
      },
      {
        title: 'Faster and Cross-Efficient Transactions',
        desc: 'Close deals quicker than a coffee break. Pixelport’s streamlined processes minimize gas fees and speed up transactions so you can seal the deal without the usual hassle or wait times. Efficiency is the name of the game.',
      },
    ],
  },
  {
    text: 'Management',
    menu: [
      {
        title: 'Decentralized Property Management',
        desc: 'Manage your real estate assets like a pro, without the middlemen. Pixelport’s decentralized platform gives you full control over your properties which allows you to handle everything from rental agreements to maintenance requests directly through the blockchain.',
      },
      {
        title: 'Real-Time Asset Tracking',
        desc: 'Keep tabs on your properties in real-time no matter where they’re located or on which blockchain they reside. Pixelport provides instant updates and detailed insights into your real estate portfolio so you’re always in the loop and can make informed decisions on the fly.',
      },
      {
        title: 'Automated Compliance',
        desc: 'Forget the paperwork. Pixelport automates compliance with local regulations and smart contract rules, ensuring your properties meet all the necessary legal requirements.',
      },
    ],
  },
];

const tabData = [
  'Digital Art & Collections',
  'Real Estate',
  'Gaming',
  'Music & Entertainment',
  'DeFi',
  'Sports & Memorabilia',
  'Crowdfunding & Investment',
  'Governance',
];

export const slides = (isBelowMd: boolean) => {
  return advantages.map(({ text, menu }, index) => {
    const slideContent = (
      <div
        className="md:px-0 sticky top-[10%] z-10"
        style={{
          top: isBelowMd ? `${10 + index * 84}px` : `${10 + index * 100}px`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
        }}
        key={index}
      >
        <div
          className="border border-black/20 rounded-[20px] py-9 px-8 md:px-28 bg-white bg-opacity-70 backdrop-blur-[10px] min-h-[600px] md:min-h-fit mx-2"
          key={`advantage-chunk-${index}`}
        >
          <img
            src="/newDesign/cardCorner.svg"
            alt="card-corner"
            className="absolute -top-[1px] -right-[1px] !hidden !md:block"
          />
          <AdvantageTabs menu={menu} text={text} />
        </div>
      </div>
    );

    return isBelowMd ? <SwiperSlide key={index}>{slideContent}</SwiperSlide> : slideContent;
  });
};

export function UseCases() {
  const {
    windowSize: { width },
  } = useWindowSize();

  const isBelowMd = width < 768;

  return (
    <div className="container mx-auto pt-[100px] md:pt-0" id="use-cases">
      <Header
        tag={'h2'}
        classNames={{
          header:
            'uppercase text-[48px] md:text-[76px] 2xl:text-[90px] leading-[39px] md:leading-[72px] font-["Arcade"] pixelport-text-shadow text-white text-center px-0 md:px-56 z-[5] relative',
        }}
      >
        Get ready to discover unbounded use cases
      </Header>
      <div>
        <div className="flex flex-wrap justify-center gap-[11px] md:gap-[25px] mt-[32px] md:mt-[53px] px-4">
          {tabData.map((name, index) => {
            return <Chip key={index} text={name} />;
          })}
        </div>
        <div className="flex flex-col gap-20 pt-[116px] mx-5 md:pb-[150px]">
          {isBelowMd && (
            <Swiper
              scrollbar={{
                hide: true,
              }}
              modules={[Scrollbar]}
              className="mySwiper overflow-visible"
            >
              {slides(isBelowMd)}
            </Swiper>
          )}
          {!isBelowMd && slides(isBelowMd)}
          <div className="md:py-2"></div>
        </div>
      </div>
    </div>
  );
}
