import { Header, TypeFormButton } from 'components';

import { GetStartedButton } from './GetStartedButton';

export function HeroSection() {
  return (
    <div className="w-auto md:w-[720px] mx-auto h-full relative mt-24 md:mt-30 2xl:mt-52 px-10">
      <div className="flex flex-col xl:w-full w-full relative z-[1]">
        <div className="flex flex-col w-full">
          <Header
            tag={'h1'}
            classNames={{
              header:
                'min-w-[340px] text-[58px] md:text-[82px]  2xl:text-[118px] leading-[50px] md:leading-[62px] 2xl:leading-[72px] font-["Arcade"] pixelport-text-shadow capitalize',
            }}
          >
            Cross Chain Ownership With PixelPort
          </Header>
          <Header
            tag={'p'}
            classNames={{
              header:
                'text-xs leading-5 md:!text-sm 2xl:!text-lg normal-case text-lightBlack pt-[17px] pixelport-text-shadow pr-[70px] md:pr-[180px] font-Jakarta text-justify',
            }}
          >
            Effortless Cross-Chain Minting, Fractionalization, and Transfer of NFTs. Effortless
            cross-chain minting, fractionalization, and transfer of NFTs. At Pixelport, we turn
            digital assets into vibrant playgrounds of imagination. Our mission? To make NFT
            creation and trading as fun, accessible, and secure as possible.
          </Header>
        </div>
        <div className="flex flex-col md:flex-row gap-0 lg:gap-10 md:gap-4 mt-4 lg:mt-[26px]">
          <div className="flex xl:items-center md:items-start gap-3 xl:gap-8 xl:flex-row md:flex-col sm:flex-row">
            <TypeFormButton formId="uvT7QzSD" as="div">
              <GetStartedButton
                title="get early access"
                arrowStroke="black"
                classNames={{
                  base: '!bg-black !text-white',
                  container:
                    'bg-white lg:group-hover:translate-x-[200px] sm:group-hover:translate-x-[165px]',
                }}
              />
            </TypeFormButton>
          </div>
          <div className="flex flex-row gap-4 md:gap-10 mt-10 md:mt-0">
            <img src="./analogLogo.svg" alt="analog" className="md:w-[153px] w-[120px]" />
            <img src="./encrypt.svg" alt="encrypt" className="md:w-[165px] w-[120px]" />
          </div>
        </div>
      </div>
    </div>
  );
}
