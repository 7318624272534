import { Props } from './types';

export const TurnOff = (props: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={22} fill="none" {...props}>
    <path
      fill="#000"
      d="M18.177 2.362C21.1 4.326 23 7.538 23 11.175 23 17.146 17.862 21.987 11.523 22 5.193 22.013.01 17.155 0 11.192c-.005-3.636 1.897-6.857 4.813-8.826.543-.362 1.299-.21 1.623.336l.733 1.227a1.008 1.008 0 0 1-.306 1.353C4.939 6.626 3.71 8.756 3.71 11.17c-.005 4.03 3.454 7.338 7.79 7.338 4.248 0 7.818-3.239 7.79-7.381-.014-2.262-1.145-4.444-3.157-5.85a1.003 1.003 0 0 1-.302-1.348l.733-1.227c.324-.541 1.076-.703 1.613-.34Zm-4.822 9.162V1.048c0-.58-.496-1.048-1.113-1.048h-1.484c-.617 0-1.113.467-1.113 1.048v10.476c0 .58.496 1.047 1.113 1.047h1.484c.617 0 1.113-.467 1.113-1.047Z"
    />
  </svg>
);
export default TurnOff;
