import { ReactHTML } from 'react';

import { PopupButton } from '@typeform/embed-react';

type FormProps = {
  formId: string;
  submitHandler?: ({ formId, responseId }: { formId: string; responseId: string }) => void;
  className?: string;
  children?: string | JSX.Element;
  as?: keyof ReactHTML | undefined;
};

export const TypeFormButton = ({ formId, submitHandler, className, children, as }: FormProps) => {
  return (
    <PopupButton id={formId} className={className} onSubmit={submitHandler} as={as}>
      {children}
    </PopupButton>
  );
};

export default TypeFormButton;
