import { Props } from './types';

export function TransferNFTIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2744_455)">
        <path
          d="M2 11.0088V10.1506C2 9.43959 2.6038 8.86324 3.34865 8.86324H23.5784V6.2886C23.5784 5.14251 25.0338 4.56992 25.8807 5.37831L30.3762 9.66937C30.9029 10.1721 30.9029 10.9872 30.3762 11.4899L25.8807 15.781C25.037 16.5862 23.5784 16.0216 23.5784 14.8707V12.2961H3.34865C2.6038 12.2961 2 11.7198 2 11.0088ZM29.4226 19.1618H9.19281V16.5872C9.19281 15.4439 7.73952 14.8664 6.89049 15.6769L2.39499 19.9679C1.86834 20.4707 1.86834 21.2858 2.39499 21.7885L6.89049 26.0795C7.73497 26.8856 9.19281 26.3191 9.19281 25.1693V22.5947H29.4226C30.1674 22.5947 30.7712 22.0183 30.7712 21.3073V20.4491C30.7712 19.7381 30.1674 19.1618 29.4226 19.1618Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2744_455">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
