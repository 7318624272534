import { useState } from 'react';

const AdvantageTabs = ({ text, menu }: { text: string; menu: any }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="flex flex-col md:flex-row gap-[10px] md:gap-[122px] pb-2">
      <div>
        <h2 className='text-[59px] md:text-[90px] uppercase font-["Arcade"] pixelport-text-shadow leading-[72px]'>
          {text}
        </h2>
        <div className="flex flex-row md:flex-col gap-4 md:gap-7 flex-wrap justify-between items-center">
          {menu?.map(({ title }: { title: string }, index: number) => {
            if (activeTab === index) {
              return (
                <div
                  className="card bg-white py-[14px] md:py-[30px] pl-2 md:pl-[18px] relative w-[46%] md:w-[368px] shadow-md"
                  key={title}
                >
                  <img
                    src="/newDesign/cardCornerSmall.svg"
                    alt="card-corner"
                    className="absolute top-[0px] right-0 h-[32px]"
                  />
                  <h6 className="w-[80%] md:w-[260px] text-xs md:text-xl font-semibold">{title}</h6>
                </div>
              );
            }

            return (
              <h2
                className="flex items-center pl-2 md:pl-[18px] cursor-pointer text-xs md:text-xl font-Jakarta !font-semibold w-[46%] md:w-[368px] h-full md:h-auto"
                onClick={() => {
                  setActiveTab(index);
                }}
                key={title}
              >
                {title}
              </h2>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <h3 className="text-lg md:text-2xl py-[34px] md:py-[46px] font-semibold font-Jakarta">
          {menu?.[activeTab].title || ''}
        </h3>
        <p className="text-sm md:text-lg font-medium font-Jakarta">
          {menu?.[activeTab].desc || ''}
        </p>
      </div>
    </div>
  );
};

export default AdvantageTabs;
